import React from "react";
import { AUTH_TOKEN } from "../config/constants";
import { getStorageValueToken } from "../utils/getMiniAppStorageData";
import { LOGIN_PEOPLE_DETAIL } from "../config/constants";

export const expireDate = (num) => {
  num = num * 1000;
  var now = Date.now();
  num = now + num;
  var date = new Date(num);
  return date.toString();
};

export const isExpire = () =>
  new Date(localStorage.getItem("expires_in")) > Date.now() ? false : true;

let loginAccess = null;
let loginAccessType = null;

// Fetch tokens during app initialization or elsewhere
const initializeTokens = () => {
  if (window.location.search.includes("miniapp")) {
    Promise.all([
      getStorageValueToken("access_token"),
      getStorageValueToken("token_type"),
    ])
      .then(([accessToken, tokenType]) => {
        loginAccess = accessToken;
        loginAccessType = tokenType;
        localStorage.setItem("access", accessToken);
        localStorage.setItem("accessType", tokenType);
      })
      .catch((error) => {
        console.error("Error fetching tokens during initialization:", error);
      });
  }
};

export const isAuthenticated = () => {
  if (window.location.search.includes("miniapp")) {
    //const loginPeopleDetail = getStorageValue("LOGIN_PEOPLE_DETAIL"); // Call this only if synchronous or pre-fetched
    return loginAccess && loginAccessType ? true : false;
  } else {
    return localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;
  }
};

// export const isAuthenticated = () => {
//   return localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;
// };

// export const isAuthenticated = () => {
//   return localStorage.getItem(AUTH_TOKEN) && !isExpire() ? true : false;
// };

export const GetParamByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
if (window.location.search.includes("miniapp")) {
  window.addEventListener("load", () => {
    initializeTokens();
  });
}
