/** =====================================================================
 *
 * NOTE: Only define routes that should be included in the sitemap.
 * IMPORTANT: Avoid adding dynamic routes with sensitive parameter values (e.g., :bookingId, :returnBookingId)
 *
 * ===================================================================== **/

const routes = {
  HOME: "/:locale",
  HOME_INAPP: "/in-app",
  HOME_INAPP_LOCALE: "/:locale/in-app",
  HOME_INWEB: "/in-web",
  MOBILE_APP_DOWNLOAD: "/:locale/mobileapp/download",
  MOBILE_APP_DOWNLOAD_INAPP: "/:locale/in-app/mobileapp/download",
  MOBILE_APP_DOWNLOAD_INWEB: "/:locale/in-web/mobileapp/download",
  FLIGHT_HOME: "/:locale/flight",
  FLIGHT_HOME_INAPP: "/:locale/in-app/flight",
  FLIGHT_HOME_INWEB: "/:locale/in-web/flight",
  VERIFY_TICKET: "/:locale/verify-ticket",
  VERIFY_TICKET_INAPP: "/:locale/in-app/verify-ticket",
  VERIFY_TICKET_INWEB: "/:locale/in-web/verify-ticket",
  VERIFY_EMAIL: "/:locale/email-verify",
  VERIFY_EMAIL_REDIRECT: "/:locale/just-email-verify",
  EMAIL_LINK_REDIRECT: "/:locale/email-link-auth",
  FORGOT_PASSPORT: "/:locale/forgot-password",
  CREATE_PASSPORT: "/:locale/create-password",
  HOTEL_HOME: "/:locale/hotel/",
  HOTEL_HOME_INAPP: "/:locale/in-app/hotel",
  HOTEL_HOME_INWEB: "/:locale/in-web/hotel",
  HOTEL_LONGSTAY: "/:locale/hotel-long-stay",
  HOTEL_LONGSTAY_INAPP: "/:locale/in-app/hotel-long-stay",
  HOTEL_LONGSTAY_INWEB: "/:locale/in-web/hotel-long-stay",
  BUS_HOME: "/:locale/bus",
  BUS_HOME_INAPP: "/:locale/in-app/bus",
  BUS_HOME_INWEB: "/:locale/in-web/bus",
  BUS_TRANSITION_FAIL: "/:locale/bus/transaction-fail",
  BUS_TRANSITION_FAIL_INAPP: "/:locale/in-app/bus/transaction-fail",
  BUS_TRANSITION_FAIL_INWEB: "/:locale/in-web/bus/transaction-fail",
  CAR_RENTAL_HOME: "/:locale/car-rental",
  CAR_RENTAL_HOME_INAPP: "/:locale/in-app/car-rental",
  CAR_RENTAL_HOME_INWEB: "/:locale/in-web/car-rental",
  BALLOON_HOME: "/:locale/balloon",
  BALLOON_HOME_INAPP: "/:locale/in-app/balloon",
  BALLOON_HOME_INWEB: "/:locale/in-web/balloon",
  VISA_HOME: "/:locale/travel-services/visa-service",
  VISA_HOME_INAPP: "/:locale/in-app/travel-services/visa-service",
  VISA_HOME_INWEB: "/:locale/in-web/travel-services/visa-service",
  VIP_HOME: "/:locale/travel-services/vip-service",
  VIP_HOME_INAPP: "/:locale/in-app/travel-services/vip-service",
  VIP_HOME_INWEB: "/:locale/in-web/travel-services/vip-service",
  INSURANCE_HOME: "/:locale/insurance",
  INSURANCE_HOME_INAPP: "/:locale/in-app/insurance",
  INSURANCE_HOME_INWEB: "/:locale/in-web/insurance",
  INSURANCE_PREMIUM_POLICY: "/:locale/insurance/premium-policy",
  INSURANCE_PREMIUM_POLICY_INAPP: "/:locale/in-app/insurance/premium-policy",
  INSURANCE_PREMIUM_POLICY_INWEB: "/:locale/in-web/insurance/premium-policy",
  TOURPACKAGES_HOME: "/:locale/tour-packages",
  TOURPACKAGES_HOME_INAPP: "/:locale/in-app/tour-packages",
  TOURPACKAGES_HOME_INWEB: "/:locale/in-web/tour-packages",
  PROMOTION_HOME: "/:locale/promotion",
  PROMOTION_HOME_INAPP: "/:locale/in-app/promotion",
  PROMOTION_HOME_INWEB: "/:locale/in-web/promotion",
  CONTACT_US: "/:locale/contact-us",
  CONTACT_US_INAPP: "/:locale/in-app/contact-us",
  CONTACT_US_INWEB: "/:locale/in-web/contact-us",
  ABOUT_US: "/:locale/about-us",
  ABOUT_US_INAPP: "/:locale/in-app/about-us",
  ABOUT_US_INWEB: "/:locale/in-web/about-us",
  PRIVACY_POLICY: "/:locale/privacy-policy",
  PRIVACY_POLICY_INAPP: "/:locale/in-app/privacy-policy",
  PRIVACY_POLICY_INWEB: "/:locale/in-web/privacy-policy",
  PRIVACY_POLICY_BOCA: "/:locale/privacy-policy-boca",
  TEAM: "/:locale/team",
  TEAM_INAPP: "/:locale/in-app/team",
  TEAM_INWEB: "/:locale/in-web/team",
  SHOW_RESULT_MESSAGE: "/:locale/show-message",
  SHOW_RESULT_MESSAGE_INAPP: "/:locale/in-app/show-message",
  REDIRECT_INTL_HOTEL: "/redirect-intl-hotel",
  MOBILE_APP_REDIRECT: "/:locale/mobileapp/redirect/*",
  MOBILE_APP_REDIRECT_INAPP: "/:locale/in-app/mobileapp/redirect/*",
  MOBILE_APP_REDIRECT_INWEB: "/:locale/in-web/mobileapp/redirect/*",
};

export const addConditionalRoutes = (clientActive) => {
  if (clientActive) {
    routes.IN_WEB = "/:locale/in-web";
    // Add more conditional routes as needed
  }
};

export default routes;
